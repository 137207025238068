(function () {

	'use-strict'

	window.constant = {


		VERSION:			'v1.5.12',


		CONSULPAGESIZE:		50,
		PAGESIZE:		25,
		APIURL:			'https://rs.universidad3m.com/suite/index.php/',
		BASEURL:		'https://rs.universidad3m.com/suite/',
		SHAREURL:               'https://rs.universidad3m.com/suite/compartir/',
		SOCKET:			'https://universidad3m.com:8083',
		LANDINGURL: 		'https://universidad3m.com/',
        	THEME:                 'universidad3m', //Clientes disponibles [default,aforebanamex, bmv, grupomexico, nafin, universidad3m]

		FIREBASE_SCORE: 	'https://us-central1-thehuman-3m.cloudfunctions.net/score',
		FIREBASE_LOGRO:     'https://us-central1-thehuman-3m.cloudfunctions.net/actions',
		FIREBASE_WEBIN:     'https://us-central1-thehuman-3m.cloudfunctions.net/webinars',
		FIREBASE_DICCI:     'https://us-central1-thehuman-3m.cloudfunctions.net/dictionary',

        /*
         *	URL Correspondiente a la ubicación de los recursos gráficos de la plataforma.
         */

        BASERECURSOSURL:	'https://rs.universidad3m.com/suite/',

        /*
         *	Modulo Correspondiente a CONSULTAS
         */

        CONSULTAS:	{

            /*
             *	Definicion de etiquetas de estatus correspondientes a las consultas.
             */

            ESTATUS:	{

                ETIQUETAS:
					[
						'Abierto', 			// 0
						'En progreso', 		// 1
						'Indefinido', 		// 2
						'Reabierto', 		// 3
						'Resuelto', 		// 4
						'Cerrada', 			// 5
					]

            },
            
            CONSTANTES: {
                CONSULTAS_ESTATUS_ABIERTO:		0,
                CONSULTAS_ESTATUS_ENPROGRESO:	1,
                CONSULTAS_ESTATUS_INDEFINIDO:	2,
                CONSULTAS_ESTATUS_REABIERTO:	3,
                CONSULTAS_ESTATUS_RESUELTO:		4,
                CONSULTAS_ESTATUS_CERRADO: 		5
            }

		},

		/*
		 *	Modulo Correspondiente a Learning Managment System (LMS) 
		 */

        LMS:	{
        	
				/*
				 *	Submodulo Correspondiente a Learning Managment System (LMS) 
				 */

				CURSOS:	{

                    /*
					 *	Definicion de etiquetas de estatus correspondientes a estatus de los objetos de aprendizaje. 
					 */

                	ESTATUS:
						[
							'Programado', 			// 0
							'En Curso', 			// 1
							'Cancelado', 			// 2
							'Reabierto', 			// 3
							'Cerrado Completo', 	// 4
							'Cerrado Incompleto', 	// 5
							'Preinscrito', 			// 6
							'Inscrito', 			// 7
							'Aprobado', 			// 8
							'Reprobado', 			// 9
							'Visitado', 			// 10
							'No Asistio', 			// 11
							'Baja'					// 12
                    	],

					VALORES_DEFAULT:{
						DURACION_HORAS_CURSO: 			10,
						CAPACIDAD_ALUMNOS_POR_CLASE: 	30,
						PATH_IMAGEN_RUTA_APRENDIZAJE: 	'img/comunidades/comunidades/default_comunidad.png'
					}

				}
		},

		/*
		 *	Constantes definidas para modulo de busqueda-
		 */

        BUSQUEDAS: {

            CONSTANTES:{

                BUSQUEDA_COMUNIDADES_PROPIETARIO:               'comunidades',
                BUSQUEDA_COMUNIDADES_TIPO_ELEMENTO:             'Comunidad',
                BUSQUEDA_NOTICIAS_PROPIETARIO:                  'noticias',
                BUSQUEDA_NOTICIAS_TIPO_ELEMENTO:                'Noticia',
                BUSQUEDA_FOROS_PREGUNTAS_PROPIETARIO:           'foros_preguntas',
                BUSQUEDA_FOROS_PREGUNTAS_TIPO_ELEMENTO:         'Foro',
                BUSQUEDA_CALENDARIO_PROPIETARIO:                'calendario',
                BUSQUEDA_CALENDARIO_TIPO_ELEMENTO:              'Evento',
                BUSQUEDA_ELEMENTOS_ASOCIADOS_PROPIETARIO:       'lms_ra',
                BUSQUEDA_ELEMENTOS_ASOCIADOS_TIPO_ELEMENTO:     'Elementos',
                BUSQUEDA_DOCUMENTOS_PROPIETARIO:                'documentos',
                BUSQUEDA_DOCUMENTOS_TIPO_ELEMENTO:              'Documento',
                BUSQUEDA_EVALUACIONES_DINAMICAS_PROPIETARIO:    'evaluacion_dinamica',
                BUSQUEDA_EVALUACIONES_DINAMICAS_TIPO_ELEMENTO:  'Evaluacion Dinamica'

            }

        },

		EVALUACIONES_DINAMICAS: {

			/*
			 *	Definicion de constantes de estatus correspondientes a estatus de los objetos de aprendizaje.
			 */

			CONSTANTES:{
				PREGUNTAS_TIPO_RESPUESTA_SI_NO:					1,
				PREGUNTAS_TIPO_RESPUESTA_UNICA:					2,
				PREGUNTAS_TIPO_RESPUESTA_MULTIPLE:				3,
				PREGUNTAS_TIPO_RESPUESTA_LISTA_DESPLEGABLE:		4,
				PREGUNTAS_TIPO_RESPUESTA_RELACIONAR_COLUMNAS:	5,
				PREGUNTAS_TIPO_RESPUESTA_PORCENTAJE:			6,
				PREGUNTAS_TIPO_RESPUESTA_PUNTUACION:			7,
				PREGUNTAS_TIPO_RESPUESTA_ABIERTA:				8,
				PREGUNTAS_TIPO_RESPUESTA_ORDENAR_RESPUESTA:		9,
				PREGUNTAS_TIPO_RESPUESTA_CALIFICAR_NIVELES:		10
			}

		}
        
	};

	window.curso_imagen = {
	    '36':'/img/cursos/planeacion_estrategica.jpg',
	    '56':'/img/cursos/productos_servicios_empresa.jpg',
	    '1':'/img/cursos/plan_idea_negocio.jpg',
	    '2':'/img/cursos/credito_banco.jpg',
	    '6':'/img/cursos/mujeres_emprededoras_empresarias.jpg',
	    '49':'/img/cursos/capital_humano_pyme.jpg',
	    '61':'/img/cursos/20pasos_restaurante.jpg',
	    '19':'/img/cursos/cadenas_productivas.jpg',
	    '64':'/img/cursos/problemas_legales_empresa.jpg',
	    '60':'/img/cursos/aprende_a_endeudarte.jpg',
	    '17':'/img/cursos/venderle_gobierno.jpg',
	    '38':'/img/cursos/regimen_incorporacion_fiscal.jpg',
	    '14':'/img/cursos/retos_empresa_familiar.jpg',
	    '50':'/img/cursos/determina_bien_costos.jpg',
	    '52':'/img/cursos/calidad_pymes.jpg',
	    '33':'/img/cursos/problemas_legales_empresa.jpg',
	    '12':'/img/cursos/ahorro_energia_beneficio_economico.jpg',
	    '44':'/img/cursos/servicio_cliente.jpg',
	    '45':'/img/cursos/presentar_proyecto_negocio.jpg',
	    '37':'/img/cursos/contabilidad.jpg',
	    '28':'/img/cursos/planeacion_estrategica.jpg',
	    '34':'/img/cursos/problemas_legales_empresa.jpg',
	    '25':'/img/cursos/redes_sociales.jpg',
	    '8':'/img/cursos/prevencion_desastres.jpg',
	    '23':'/img/cursos/buro_credito.jpg',
	    '21':'/img/cursos/cadenas_productivas.jpg',
	    '20':'/img/cursos/eficiencia_energetica_oportunidad_negocio.jpg',
	    '18':'/img/cursos/prevencion_desastres.jpg',
	    '13':'/img/cursos/obtener_microcredito.jpg',
	    '15':'/img/cursos/vender_micro_pequena_empresa.jpg',
	    '5':'/img/cursos/negocio_marca_registrada.jpg',
	    '4':'/img/cursos/20pasos_tienda.jpg',
	    '11':'/img/cursos/20pasos_restaurante.jpg',
	    '7':'/img/cursos/promocionar_eficientemente_producto.jpg',
	    '57':'/img/cursos/empresa_familiar_empresa_institucional.jpg',
	    '58':'/img/cursos/empresas_socialmente_responsables.jpg',
	    '29':'/img/cursos/retos_empresa_familiar.jpg',
	    '35':'/img/cursos/productos_servicios_empresa.jpg',
		'26':'/img/cursos/calidad_pymes.jpg',
	    '55':'/img/cursos/calidad_pymes.jpg',
	    '66':'/img/cursos/calidad_pymes.jpg',
	    '67':'/img/cursos/calidad_pymes.jpg',
	    '30':'/img/cursos/calidad_pymes.jpg',
        '68':'/img/cursos/forjando_innovadores_protegidos.jpg',
	    '70':'/img/cursos/negocio_marca_registrada.jpg'

	};

    window.add_user_action = function(id_usuario,id_accion,nombre_usuario){
        $.ajax({
            url: window.constant.FIREBASE_LOGRO,
            type:'POST',
            data:{
              'method':'add_action',
              'data':{
                'id_usuario':		id_usuario,
                'id_accion':		id_accion,
                'nombre_usuario':	nombre_usuario,
              }
            },
            success:function(data){
              console.log(data);
            }
        });
    };


	/*
     * mask - Función utilitaria. Enmascara la aplicación con el elemento {{partial "loading"}}.
     *
     *
     * En mascara la aplicación con el elemento {{partial "loading"}}. Utilizado al ejecutar alguna 
     * acción que requiera espera que el usuario espere que se finalize alguna acción.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-10
     * @param   void
     * @return  void
     */

	window.mask 		= function(){ $('#loading').fadeIn('slow'); };
		
	/*
     * mask - Función utilitaria. Desenmascara la aplicación con el elemento {{partial "loading"}}.
     *
     *
     * En mascara la aplicación con el elemento {{partial "loading"}}. 
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-10
     * @param   void
     * @return  void
     */
	 
	window.unmask		= function(){ $('#loading').fadeOut('slow'); };
	

	
	/*
     * showModalError - Función utilitaria. Muestra un mensaje a usuario.
     *
     *
     * Función utilitaria. Muestra al usuario un mensaje, tomando los atributos enviados.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-10
     * @param   object params_object Objeto con los detalle del mensaje a mostrar.
     * @return  void
     */

	window.showModal 	= function( params_object ){

        $('#modal-avisos .modal-header').text( params_object.titulo );
        $('#modal-avisos .modal-body'  ).html( params_object.mensaje );
        $('#modal-avisos').modal('show');

	};


	/*
     * solveResourceURL - Función utilitaria. Formatea la URL de imagen enviada a una URL absoluta.
     *
     *
	 * Sí la URL envaida no contiene un formato de URL Absoluta hacia BASERECURSOSURL, retorrna la yuxtaposición de  BASERECURSOSURL y la URL de imagen enviada.
	 * En caso contrario retorna la URL son cambio alguno.
	 *
	 *
     * Función utilitaria. Muestra un mensaje genérico de error al usuario.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-10
     * @param   string image_url Path/Ruta de la ubicación de un recurso.
     * @return  string URL en formato de URL absoluta.
     */

     window.solveResourceURL = function( resouce_url ) { 
		if( !resouce_url ){ resouce_url = ''; }
		if ( resouce_url.indexOf(constant.BASERECURSOSURL) == -1 ){
            if(resouce_url.indexOf('http')!=0){
                resouce_url = constant.BASERECURSOSURL + resouce_url;
            }
		}
		return resouce_url;
     };

	/*
     * solveImageURL - Función utilitaria. Formatea la URL de imagen enviada a una URL absoluta.
     *
     *
	 * Sí la URL envaida no contiene un formato de URL Absoluta hacia BASERECURSOSURL, retorrna la yuxtaposición de  BASERECURSOSURL y la URL de imagen enviada.
	 * En caso contrario retorna la URL son cambio alguno.
	 *
	 *
     * Función utilitaria. Muestra un mensaje genérico de error al usuario.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-10
     * @param   string image_url Path/Ruta de la ubicación de un recurso.
     * @return  string URL en formato de URL absoluta.
     */


	window.solveImageURL= function( image_url ){

		return solveResourceURL( image_url );

	}

	/*
     * showModalError - Función utilitaria. Muestra un mensaje genérico de error al usuario.
     *
     *
     * Función utilitaria. Muestra un mensaje genérico de error al usuario.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-10
     * @param   void
     * @return  void
     */

	window.showModalError = function(){

        showModal( { titulo: 'Error en sistema.', mensaje : 'Ups! acaba de suceder algo inesperado. Por favor intente nuevamente en otro momento.' } );

    };


	/*
     * isFunction - Función utilitaria. Válida si el parametro enviado es una función.
     *
     *
     * Función utilitaria. si el parametro enviado es una función de javascript, devuelve true, en caso contrario false.
     *
     *
     * @author  [YRM] Maurilio Cruz Morales
     *                  maurilio.cruz@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2018-10-10
     * @param   mixed parameter_mixed Parametro a evaluar.
     * @return  bool Si el parametro enviado es una función de javascript, devuelve true, en caso contrario false.
     */

	 window.isFunction = function( parameter_mixed ){

	 	return ( (typeof parameter_mixed) == "function" )?true:false;

	 };

     /*
     * validateDictionary - Función utilitaria. Válida si el texto enviado contiene palabras restringidas.
     *
     *
     * Función utilitaria. Si el texto introducido contiene palabras restringidas envia mensaje alerta a usuario indicando dichas palabras.
     *
     *
     * @author  Juan Carlos Santana Arana
     *                  juancarlos.santana@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2019-08-14
     * @param   str Parametro a evaluar.
     * @return  bool Si el parametro enviado es una función de javascript, devuelve true, en caso contrario false.
     */
    window.validateDictionary = function(str){
        let palabrasEnTexto = str.toLowerCase().split(" ");
        let arrayCoincidencias = window.searchArrayIntoOtherArray(palabrasEnTexto,window.diccionario);
        if(arrayCoincidencias.length){
            showModal( { titulo: 'Alerta!', mensaje : 'El mensaje que intenta enviar contiene las siguientes palabras restringidas:<br><br> <ul><li class="text-capitalize">' + arrayCoincidencias.join().replace(/,/gi,"</li><li class='text-capitalize'>") + "</li></ul> <div class='text-center'>Por favor edite su mensaje y vuelva a enviar</div>" } );
            return false;
        }
        else
            return true;
    };
     /*
     * searchArrayIntoOtherArray - Función utilitaria. Busca las coincidencias de un array a otro array.
     *
     *
     * Función utilitaria. Busca los valores de primary array dentro de secondary array, en caso de no encontrase se elimina el indice de primary array.
     *
     *
     * @author  Juan Carlos Santana Arana
     *                  juancarlos.santana@thehumantalent.com  maurilio.cruz.m@gmail.com
     *              Tel. Oficina + 52 (55) 4333 3433 Ext. 107
     *              Tel. celular (55) 6702 6252
     *
     * @date    2019-08-15
     * @param   str Parametro a evaluar.
     * @return  bool Si el parametro enviado es una función de javascript, devuelve true, en caso contrario false.*/
    window.searchArrayIntoOtherArray = function(primaryArray, secondaryArray){
        return  [...new Set(primaryArray.filter(value => -1 !== secondaryArray.indexOf(value)))];
    }

})();
